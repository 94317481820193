import React, { useContext, useEffect, useState } from "react";
import "../css/Canal.css";
import AppContext from "../AppContext";
import download from "downloadjs";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Loading, Report } from "notiflix";
import { Padding } from "@mui/icons-material";

function CodigoGen(props) {
  const { data, idComplaint, urlImageState, setGetComplainantInfo } =
    useContext(AppContext);
  let { nameCompany } = useParams();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // LEON: Cambiar URL
    const url = window.api_base_url + `denuncia/${idComplaint}`;
    Loading.circle();
    axios
      .get(url)
      .then((response) => {
        setGetComplainantInfo(response.data);
        navigate(`/${nameCompany}/estado-denuncia/${idComplaint}`);
      })
      .catch((error) =>
        Report.failure(
          "Identificador incorrecto",
          "Por favor, compruebe que el identificador es correcto y vuelva a intentarlo.",
          "Ok"
        )
      )
      .finally(() => Loading.remove());
  };

  return (
    <div>
      <h1>{data.razonSocial}</h1>

      <div>
        <h4>Su denuncia ha sido enviada con éxito</h4>
        <p style={{ textAlign: "center", paddingRight: "2rem", paddingLeft: "2rem"}}>
          Para conocer el estado de su denuncia introduzca el siguiente
          identificador en el portal de denuncias
        </p>
        <p className="Codigo">{idComplaint}</p>
      </div>

      <div id="contBotonesCodigo" style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
        <div>
          <button
            style={{
              paddingRight: "3rem",
              paddingLeft: "3rem",
              fontSize: "16px",
              marginTop: "30px",
              width: "300px",
            }}
            // onClick={downloadReport}
            onClick={() => window.open(urlImageState, "_blank")}
          >
            Descargar justificante
          </button>
          {/* <Link
            to={`/${nameCompany}/estado-denuncia/${idComplaint}`}
            rel="noopener noreferrer"
          >
            <button style={{ width: "250px" }}>Consultar Denuncia</button>
          </Link> */}

        </div>
        
        <div>
          <button
            style={{
              paddingRight: "3rem",
              paddingLeft: "3rem",
              fontSize: "16px",
              marginTop: "30px",
              width: "300px",
            }}
            // style={{
            //   width: "200px",
            //   marginTop: "50px",
            //   marginBottom: "100px",
            //   fontSize: "16px",
            //   marginLeft: "100px",
            // }}
            // disabled={!comprobarId(id)}
            onClick={handleButtonClick}
          >
            Consultar Denuncia
          </button>
        </div>

        <Link to={`/${nameCompany}`}>
          <button
            style={{
              paddingRight: "3rem",
              paddingLeft: "3rem",
              fontSize: "16px",
              marginTop: "30px",
              marginBottom: "100px",
              width: "300px",
            }}
          >
            Volver Inicio
          </button>
        </Link>
      </div>
    </div>
  );
}

export default CodigoGen;
