import React, { useContext, useEffect } from "react";
import "../css/Canal.css";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppContext from "../AppContext";
import axios from "axios";
import download from "downloadjs";
import { format } from "date-fns";
import DescriptionIcon from "@mui/icons-material/Description";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import {
  Box,
  Modal,
  IconButton as MuiIconButton,
  Typography,
  styled,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Loading, Report } from "notiflix";

const IconButton = styled(MuiIconButton)(
  {
    color: "white",
    backgroundColor: "#0084fb",
  },
  {
    "&:hover": {
      color: "white",
      backgroundColor: "#FF5D00",
    },
  }
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Estado() {
  const navigate = useNavigate();
  let { id, nameCompany } = useParams();
  const { data, getComplainantInfo, setGetComplainantInfo, setUrlPath } =
    useContext(AppContext);
  const [selectedReport, setSelectedReport] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = (field) => {
    setSelectedReport(field);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const downloadReport = (documentUrl) => {
    const newTab = window.open(`${documentUrl}`, "_blank");
    newTab.focus();
    return;
  };

  useEffect(() => {
    if (getComplainantInfo.historyDenuncia.length === 0) {
      const url = window.api_base_url + `denuncia/${id}`;
      Loading.circle();
      axios
        .get(url)
        .then((response) => {
          setGetComplainantInfo(response.data);
          navigate(`/${nameCompany}/estado-denuncia/${id}`);
        })
        .catch((error) =>
          Report.failure(
            "Identificador incorrecto",
            "Por favor, compruebe que el identificador es correcto y vuelva a intentarlo.",
            "Ok"
          )
        )
        .finally(() => Loading.remove());
    }

    if (data.razonSocial === "") setUrlPath(nameCompany);
  }, []);

  const columns = [
    {
      id: "dateCreation",
      label: "Fecha",
      minWidth: 100,
      maxWidth: "fit-content",
      align: "center",
      format: (value) => format(value, "dd-MM-yyyy"),
    },
    { id: "subject", label: "Asunto", minWidth: 100, align: "center" },
    {
      id: "text",
      label: "Texto",
      minWidth: 100,
      align: "center",
    },
    {
      id: "url",
      label: "Archivo",
      minWidth: 100,
      align: "center",
      format: (value) => {
        if (value === undefined) return;
        return (
          <button
            style={{
              // position: "absolute",
              // marginTop: "-31px",
              margin: "1rem",
              fontSize: "18px",
            }}
            onClick={() => downloadReport(value)}
          >
            Descargar documento
          </button>
        );
      },
    },
    // {
    //   id: "url",
    //   label: "Justificante",
    //   minWidth: 100,
    //   align: "center",
    //   format: (value) => {
    //     console.log("URKL", value);
    //     if (value === undefined) return;
    //     return (
    //       <button
    //         style={{
    //           margin: "1rem",
    //           fontSize: "18px",
    //           // width: "220px",
    //           // fontSize: "18px",
    //           // marginLeft: "2rem",
    //           // whiteSpace: "nowrap",
    //         }}
    //         onClick={() => window.open(getComplainantInfo.url, "_blank")}
    //       >
    //         Justificante
    //       </button>
    //     );
    //   },
    // },
  ];

  console.log("getComplainantInfo", getComplainantInfo);

  // function createData(dateCreation, subject, topic, text, document, report) {
  //   return { dateCreation, subject, topic, text, document, report };
  // }

  const rows = getComplainantInfo.historyDenuncia;
  // [
  //   // createData(
  //     <div>{(getComplainantInfo.dateCreation, "dd-MM-yyyy")}</div>,
  //     <div>{getComplainantInfo.subject}</div>,
  //     <IconButton
  //       style={{
  //         height: "2.3rem",
  //         width: "2.3rem",
  //       }}
  //       onClick={() => handleOpen(selectedReport)}
  //     >
  //       <DescriptionIcon />
  //     </IconButton>,
  //     <button
  //       id="casillasTabla"
  //       style={{
  //         margin: "1rem",
  //         fontSize: "18px",
  //       }}
  //       onClick={() => downloadReport(selectedReport.url)}
  //     >
  //       Descargar documento
  //     </button>,
  //     <button
  //       id="casillasTabla"
  //       style={{
  //         margin: "1rem",
  //         fontSize: "18px",
  //       }}
  //       onClick={() => window.open(getComplainantInfo.url, "_blank")}
  //     >
  //       Justificante
  //     </button>
  //   // ),
  // ];

  function StickyHeadTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const theme = createTheme(
      {
        palette: {
          primary: { main: "#1976d2" },
        },
      },
      esES
    );

    console.log("ROWS", rows);

    return (
      <Paper
        id="tablaMui"
        xs={{
          overflow: "hidden",
        }}
      >
        <TableContainer
          sx={{
            maxWidth: 1200,
            maxHeight: 440,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    id="casillasTabla"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index, rows) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      rows={rows}
                      index={index}
                      getComplainantInfo={() => getComplainantInfo(row)}
                    >
                      {columns.map((column) => {
                        console.log("DATOSSSS", row, column);
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? (
                              // && typeof value === "number"
                              column.format(value)
                            ) : column.id === "text" ? (
                              <IconButton
                                style={{
                                  // position: "absolute",
                                  // marginLeft: "205px",
                                  // marginTop: "-31px",
                                  height: "2.3rem",
                                  width: "2.3rem",
                                }}
                                onClick={() => handleOpen(row)}
                              >
                                <DescriptionIcon />
                              </IconButton>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <ThemeProvider theme={theme}>
          <TablePagination
            sx={{
              paddingLeft: 6,
              paddingRight: 6,
            }}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Paper>
    );
  }

  return (
    <div>
      <h1>{data.razonSocial}</h1>
      <h4 style={{ marginBottom: "25px", textAlign: "center", color: "black" }}>
        Estado de su denuncia
      </h4>

      <StickyHeadTable
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
      ></StickyHeadTable>

      {/* <div
        id="tabla"
        style={{ display: "flex", flexDirection: "column", padding: "1rem" }}
      >
        <table>
          <tr>
            <div id="contFilaUno">
              <th>
                <div>Fecha</div>
              </th>
              <th>
                <div>Asunto</div>
              </th>
              <th>
                <div>Texto</div>
              </th>
              <th>
                <div>Archivos</div>
              </th>
              <th>
                <div>Justificante</div>
              </th>
            </div>
          </tr>

          {getComplainantInfo.historyDenuncia.map((fila, index) => (
            <tr key={index}>
              <td>
                <div>{format(fila.dateCreation, "dd-MM-yyyy")}</div>
              </td>

              <td>
                <div>{fila.subject}</div>
              </td>

              <td>
                <div>
                  <IconButton
                    style={{
                      // position: "absolute",
                      // marginLeft: "205px",
                      // marginTop: "-31px",
                      height: "2.3rem",
                      width: "2.3rem",
                    }}
                    onClick={() => handleOpen(fila)}
                  >
                    <DescriptionIcon />
                  </IconButton>
                </div>
              </td>

              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {!fila.url && (
                    <button
                      style={{
                        // position: "absolute",
                        // marginTop: "-31px",
                        margin: "1rem",
                        fontSize: "18px",
                      }}
                      onClick={() => downloadReport(fila.url)}
                    >
                      Descargar documento
                    </button>
                  )}
                </div>
              </td>

              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {index == 0 && (
                    <button
                      style={{
                        margin: "1rem",
                        fontSize: "18px",
                        // width: "220px",
                        // fontSize: "18px",
                        // marginLeft: "2rem",
                        // whiteSpace: "nowrap",
                      }}
                      onClick={() =>
                        window.open(getComplainantInfo.url, "_blank")
                      }
                    >
                      Justificante
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <Typography variant="h6" component="h2">
                {selectedReport.subject}
              </Typography>
              <Typography sx={{ mt: 2 }}>{selectedReport.report}</Typography>
            </Box>
          </Modal>
        </table>
      </div> */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "2rem",
          marginBottom: "100px",
        }}
      >
        <div
          style={{
            marginTop: "80px",
            marginBottom: "100px",
            display: "flex",
            gap: "2rem",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {/* <Link>
            <button
              style={{
                paddingRight: "2rem",
                paddingLeft: "2rem",
                fontSize: "16px",
              }}
              onClick={() => window.open(getComplainantInfo.url, "_blank")}
            >
              Justificante
            </button>
          </Link> */}
          <Link
            to={`/${nameCompany}/estado-denuncia/${id}/alegacion`}
            rel="noopener noreferrer"
          >
            <button
              style={{
                paddingRight: "2rem",
                paddingLeft: "2rem",
                fontSize: "16px",
              }}
            >
              Respuesta
            </button>
          </Link>
          <Link
            to={`/${nameCompany}/consultar-denuncia`}
            rel="noopener noreferrer"
          >
            <button
              style={{
                paddingRight: "2rem",
                paddingLeft: "2rem",
                fontSize: "16px",
              }}
            >
              Atrás
            </button>
          </Link>
        </div>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            {selectedReport.subject}
          </Typography>
          <Typography sx={{ mt: 2 }}>{selectedReport.report}</Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default Estado;
