import React, { useContext, useState } from "react";
import "../css/Canal.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppContext from "../AppContext";
import axios from "axios";
import { Loading } from "notiflix";

function Alegaciones(props) {
  const navigate = useNavigate();
  let { id, nameCompany } = useParams();
  const [asunto, setAsunto] = useState("");
  const [cuadro, setCuadro] = useState("");
  const [uploadedFile, setUploadedFile] = useState();
  const { data } = useContext(AppContext);

  const previewFileFront = (event) => {
    event.preventDefault();

    const file = document.getElementById("input").files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    setUploadedFile(file);
  };

  async function sendFile() {
    const url = window.api_base_url + "UploadDocumentDenunciaServlet";
    const formData = new FormData();
    formData.append("idCompany", data.idCompany);
    formData.append("file", uploadedFile);
    formData.append("fileName", uploadedFile.name);
    formData.append("folderS3Bucket", "AttachmentsDenuncia");
    Loading.circle();
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status === 200) {
          // setUrlImageState(response.data.url);
          sendAlegation(response.data.url);
        }
      })
      .catch((error) => {
        console.error("Error sending alegation:", error);
        Loading.remove();
      });
  }

  function sendAlegation(urlImage = false) {
    // // LEON: Cambiar url
    const url = window.api_base_url + `denuncia/alegacion/${id}`;

    // LEON: Cambiar keys
    const denunciaVo = {
      report: cuadro,
      subject: asunto,
      ...(urlImage && {
        url: urlImage,
      }),
    };

    Loading.circle();

    axios
      .post(url, denunciaVo)
      .then((response) => {
        if (response.status === 200) {
          navigate(`/${nameCompany}/estado-denuncia/${id}`);
          window.location.reload();
        }
      })
      .catch((error) => console.error("Error sending complaint:", error))
      .finally(() => Loading.remove());
  }

  return (
    <div>
      <h1>{data.razonSocial}</h1>
      <div className="Titulo">
        <h4 style={{ textAlign: "center", marginBottom: "10px" }}>
          Alegaciones
        </h4>
      </div>

      <div style={{ textAlign: "center" }}>
        <div>
          <textarea
            style={{ fontSize: "20px", width: "80%", resize: "none" }}
            placeholder="Introduzca el asunto..."
            rows={1}
            cols={75}
            onChange={(event) => setAsunto(event.target.value)}
          />
          <textarea
            style={{ fontSize: "20px", width: "80%", resize: "none" }}
            placeholder="Introduzca el texto..."
            rows={10}
            cols={75}
            onChange={(event) => setCuadro(event.target.value)}
          />
        </div>

        <div style={{ textAlign: "left", marginLeft: "10%" }}>
          {/* <button>Adjuntar documentación</button> */}

          <input
            accept="image/jpg, image/jpeg, application/pdf"
            id="input"
            type="file"
            onChange={previewFileFront}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "2rem",
            marginBottom: "100px",
            marginTop: "30px",
          }}
        >
          <Link
            to={`/${nameCompany}/estado-denuncia/${id}`}
            rel="noopener noreferrer"
          >
            <button
              style={{
                paddingRight: "2rem",
                paddingLeft: "2rem",
                fontSize: "16px",
                // width: "150px",
                // marginTop: "100px",
                // marginRight: "30px",
                // marginBottom: "100px",
              }}
            >
              Atrás
            </button>
          </Link>

          <button
            style={{
              paddingRight: "2rem",
              paddingLeft: "2rem",
              fontSize: "16px",
            }}
            disabled={!cuadro}
            className={cuadro ? "" : "botonDesactivado"}
            // onClick={sendAlegation}
            onClick={() => {
              if (uploadedFile !== undefined) sendFile();
              else sendAlegation(false);
            }}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
}

export default Alegaciones;
