import React, { useContext, useEffect } from "react";
import "../css/Canal.css";
import { useState } from "react";
import Select from "react-select";
import { Option } from "../docs/data.ts";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppContext from "../AppContext";
import axios from "axios";
import { Loading } from "notiflix";

function App() {
  let { nameCompany } = useParams();
  const navigate = useNavigate();
  const [complaintText, setComplaintText] = useState("");
  const [uploadedFile, setUploadedFile] = useState();
  const [centerOptions, setCenterOptions] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState();
  const [selectedCenterError, setSelectedCenterError] = useState(false);
  const {
    data,
    setIdComplaint,
    complainantInfo,
    setComplainantInfo,
    urlImageState,
    setUrlImageState,
  } = useContext(AppContext);

  useEffect(() => {
    const getCenters = async () => {
      try {
        // LEON: Cambiar URL
        const url = window.api_base_url + "centro/" + data.idCompany;

        const response = await fetch(url);

        // LEON: Guardar en este estado los centros que recibes en la llamada. Ahora mismo se ponen los mockeados Option

        const jsonData = await response.json();
        console.log(jsonData);
        setCenterOptions(jsonData);
        //setCenterOptions(Option);
      } catch (error) {
        console.error("Error fetching centers:", error);
      }
    };

    getCenters();
  }, []);

  async function sendFile() {
    const url = window.api_base_url + "UploadDocumentDenunciaServlet";
    const formData = new FormData();
    formData.append("idCompany", data.idCompany);
    formData.append("file", uploadedFile);
    formData.append("fileName", uploadedFile.name);
    formData.append("folderS3Bucket", "AttachmentsDenuncia");
    Loading.circle();
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status === 200) {
          setUrlImageState(response.data.url);
          sendComplaint(response.data.url);
        }
      })
      .catch((error) => {
        console.error("Error sending complaint:", error);
        Loading.remove();
      });
  }

  function sendComplaint(urlImage) {
    // // LEON: Cambiar url
    const url = window.api_base_url + "denuncia";

    // LEON: Cambiar keys
    const denunciaVo = {
      ...complainantInfo,
      idCompany: data.idCompany,
      idCentro: selectedCenter?.idCentro,
      ...((urlImage || urlImageState !== "") && {
        url: urlImage ?? urlImageState,
      }),
    };

    Loading.circle();

    axios
      .post(url, denunciaVo)
      .then((response) => {
        if (response.status === 200) {
          // LEON: Aquí se debería recibir el id de la denuncia, que se guarda en el siguiente estado setIdComplaint. Meterle el response correcto
          // setIdComplaint(response.data.id_complaint);
          // console.log("RESPONSE DENUNCNIA", response.data.denunciaVo);
          setIdComplaint(response?.data?.codigoDenuncia);
          setUrlImageState(response?.data?.url);
          navigate(`/${nameCompany}/formulario-denuncia/elegir-centro/codigo`);
        }
      })
      .catch((error) => console.error("Error sending complaint:", error))
      .finally(() => Loading.remove());
  }

  const previewFileFront = (event) => {
    event.preventDefault();
    const file = document.getElementById("input").files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }
    console.log("previewFileFront", file);
    setUploadedFile(file);
  };

  const handleSelectChange = (CentroOption) => {
    setSelectedCenter(CentroOption);
    setSelectedCenterError(false);
  };
  console.log(
    "complainantInfo.report",
    complainantInfo.report,
    complainantInfo.report === ""
  );
  return (
    <div>
      <h1>{data.razonSocial}</h1>

      <div id="textoInfo">
        {centerOptions.length > 0 && (
          <div class="selector">
            <p>Seleccione el centro:</p>
            <Select
              id="selectorBar"
              options={centerOptions}
              value={selectedCenter}
              onChange={handleSelectChange}
              // defaultValue={centerOptions?.[0]}
              placeholder="Seleccionar centro"
              className={selectedCenterError ? "selectError" : ""}
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: "18px",
            marginTop: "30px",
          }}
        >
          <p style={{ fontSize: "18px" }}>Formule la denuncia:</p>
          <div id="cuadroText">
            <textarea
              style={{ width: "100%", fontSize: "20px", resize: "none" }}
              id="cuadro"
              placeholder="Introduzca el texto..."
              rows={10}
              cols={110}
              required="true"
              // onChange={(event) => setComplaintText(event.target.value)}
              onChange={(e) =>
                setComplainantInfo({
                  ...complainantInfo,
                  report: e.target.value,
                })
              }
            ></textarea>
          </div>

          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            {/* <button>Adjuntar documentación</button> */}

            <input
              accept="image/jpg, image/jpeg, image/png, application/pdf"
              id="input"
              type="file"
              onChange={previewFileFront}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div
          id="contBotonesCentro"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          <Link
            to={`/${nameCompany}/formulario-denuncia`}
            rel="noopener noreferrer"
            style={{
              maxWidth: "200px",
            }}
          >
            <button
              id="botonAtrasDenuncia"
              style={{
                paddingRight: "2rem",
                paddingLeft: "2rem",
                fontSize: "16px",
                marginTop: "60px",
                marginBottom: "100px",
              }}
            >
              Atrás
            </button>
          </Link>
          <button
            id="botonEnviarDenuncia"
            style={{
              paddingRight: "2rem",
              paddingLeft: "2rem",
              fontSize: "16px",
              marginTop: "60px",
              marginBottom: "100px",
            }}
            onClick={() => {
              console.log(
                "selectedCenter",
                selectedCenter,
                selectedCenter === null,
                selectedCenter === undefined
              );
              if (uploadedFile !== undefined) sendFile();
              else if (selectedCenter === undefined && centerOptions.length > 0)
                setSelectedCenterError(true);
              else sendComplaint(false);
            }}
            disabled={
              complainantInfo.report == "" ||
              (centerOptions.length > 0 && selectedCenter === "")
            }
          >
            Enviar Denuncia
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
