import React, { useContext } from "react";
import "../css/Canal.css";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppContext from "../AppContext";
import axios from "axios";
import { Loading, Report } from "notiflix";

function CodigoEnv() {
  const [id, setId] = useState("");
  const { data, setGetComplainantInfo } = useContext(AppContext);
  const navigate = useNavigate();
  let { nameCompany} = useParams();

  function comprobarId(id) {
    const regexId = /^[A-Za-z0-9]{8}$/;
    return regexId.test(id);
  }

  const inputElement = document.getElementById("myInput");

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleButtonClick();
    }
  };

  inputElement?.addEventListener("keydown", handleKeyDown);

  const handleButtonClick = () => {
    if (comprobarId(id)) {
      // LEON: Cambiar URL
      const url = window.api_base_url + `denuncia/${id}/${data.idCompany}`;
      Loading.circle();
      axios
        .get(url)
        .then((response) => {
          setGetComplainantInfo(response.data);
          navigate(`/${nameCompany}/estado-denuncia/${id}`);
        })
        .catch((error) =>
          Report.failure(
            "Identificador incorrecto",
            "Por favor, compruebe que el identificador es correcto y vuelva a intentarlo.",
            "Ok"
          )
        )
        .finally(() => Loading.remove());
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <h1>{data.razonSocial}</h1>

      <p style={{ paddingLeft: "2rem", paddingRight: "2rem", fontSize: "20px"}}>
        Introduzca la clave identificativa de su denuncia
      </p>

      <div style={{ textAlign: "center" }}>
        <label>
          <input
            id="myInput"
            type="text"
            value={id}
            onChange={(event) => {
              setId(event.target.value);
            }}
            placeholder="ID"
            style={{
              textAlign: "center",
              marginBottom: "40px",
              marginTop: "10px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              border: `1px solid ${
                !comprobarId(id) && id !== "" ? "red" : "black"
              }`,
            }}
          />
        </label>
      </div>

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "2rem", marginBottom: "100px" }}>
        <Link to={`/${nameCompany}`} rel="noopener noreferrer">
          <button style={{
            paddingRight: "2rem",
            paddingLeft: "2rem",
            fontSize: "16px"
            // width: "200px",
          }}>
            Atrás
          </button>
        </Link>

        <button
          style={{
            paddingRight: "1rem",
            paddingLeft: "1rem",
            fontSize: "16px",
            // width: "200px",
            // marginTop: "50px",
            // marginBottom: "100px",
            // marginLeft: "100px",
          }}
          disabled={!comprobarId(id)}
          onClick={handleButtonClick}
        >
          Consultar
        </button>
      </div>
    </div>
  );
}

export default CodigoEnv;
