import React, { useContext, useEffect } from "react";
import "../css/Canal.css";
import "../css/Normas.css";
import { useParams } from "react-router-dom";
import AppContext from "../AppContext";

function NormasUso() {
  let { nameCompany } = useParams();
  const { setUrlPath } = useContext(AppContext);
  useEffect(() => {
    setUrlPath(nameCompany);
  }, []);

  return (
    <div>
      <h3 style={{ marginTop: "50px" }}>
        NORMATIVA DE FUNCIONAMIENTO DEL CANAL DE DENUNCIAS{" "}
      </h3>
      <div id="textoNormas">
        <dl
          class="numerated-list"
          style={{ marginLeft: "5%", marginRight: "5%" }}
        >
          <dt>OBJETO</dt>
          <ul>
            <p>
            El objeto del presente procedimiento es regular la recepción, tratamiento, investigación y resolución de las denuncias sobre presuntas irregularidades o incumplimientos cometidos por empleados, agentes comerciales, directivos o socios de negocio, o cualquier otra persona de la entidad o ajena a esta, que puedan surgir en el ejercicio de la organización, así como situaciones de riesgos por exposiciones derivadas de las interconexiones con la Administración Pública y otros organismos públicos internacionales, todo ello de conformidad con lo establecido en la 
            <a 
              href="https://www.boe.es/buscar/act.php?id=BOE-A-2023-4513"
              style={{
                textDecoration:"none",                
              }}
            >  Ley 2/2023, de 20 de febrero, reguladora de la protección de las personas que informen sobre infracciones normativas y de lucha contra la corrupción.</a>
            </p>

            <p>
              El canal de denuncias es accesible a través de la web corporativa, facilitando su uso a empleados, agentes comerciales, directivos, socios de negocio, y cualquier interesado.
            </p>

            <p>
              El objetivo del canal de denuncias es canalizar y facilitar la formulación segura de cualquier comunicación sobre sospechas de conductas irregulares, malas prácticas comerciales, y cualquier acción u omisión que pudiera constituir infracciones del Derecho de la UE, o acciones u omisiones que puedan ser constitutivas de infracción penal o administrativa grave o muy grave.
            </p>

            <p>
              El presente procedimiento tiene como finalidad asegurar que durante todo el proceso se garantice eficazmente la protección adecuada frente a las represalias que puedan sufrir las personas físicas informantes, la protección de la privacidad de las personas involucradas y la confidencialidad sobre los datos contenidos en la denuncia, pudiendo realizarse la denuncia de forma anónima si así lo considera el denunciante.
            </p></ul>

          <dt>DEFINICIONES</dt>
          <ul>
            <li style={{
              fontSize:"20px",
            }}>
              <strong>Informante o denunciante:</strong> Persona que, en virtud de lo previsto en el presente procedimiento, comunica una incidencia o denuncia una irregularidad.
            </li>

            <li style={{
              fontSize:"20px",
            }}>
              <strong>Afectado o denunciado:</strong>  Persona que, en virtud de lo previsto en el presente procedimiento, sea acusado por el informante de la supuesta comisión de una irregularidad.
            </li>

            <li  style={{
              fontSize:"20px",
            }}>
              <strong>Irregularidad o incumplimiento:</strong>  Conductas irregulares, malas prácticas comerciales, y cualquier acción u omisión que pudiera constituir infracciones del Derecho de la UE, o acciones u omisiones que puedan ser constitutivas de infracción penal o administrativa grave o muy grave.
            </li>

            <li style={{
              fontSize:"20px",
            }}>
              <strong>Sistema de Información:</strong> Sistema informático en el que se integran el canal de información de denuncias accesible a través de la web corporativa. En el Sistema se tramitan todas las comunicaciones que se cursen a través de los canales indicados y aquellas que se reciban por otras vías que deberán informarse con carácter inmediato a la entidad, como Responsable del Sistema Interno de Información. El tratamiento de la información se hará de acuerdo con las previsiones de la ley 2/2023 de 20 de febrero.
            </li>

            <li style={{
              fontSize:"20px",
            }}>
              <strong>Gestión del Sistema de Información por tercero externo:</strong> Aucon Asesores S.L. (a través de la plataforma Datagest), tiene la consideración de gestor del Sistema de información, a efectos de lo señalado en el artículo 6.1 de la Ley 2/2023.
            </li>

            <li style={{
              fontSize:"20px",
            }}>
              <strong>Responsable del Sistema interno de información:</strong> persona física de la entidad nombrada por la misma como responsable del sistema, de su tramitación y utilización del software implantado para su aplicación.
            </li>

            <p>
            Las definiciones de los términos utilizados en este procedimiento, no incluidos en este apartado, tendrán el alcance establecido en la 
            <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2023-4513"
            style={{
              textDecoration:"none",                
            }}
            >Ley 2/2023, de 20 de febrero, reguladora de la protección de las personas que informen sobre infracciones normativas y de lucha contra la corrupción</a> y legislación aplicable.
            </p>
          </ul>
          <dt>ÁMBITO PERSONAL DE APLICACIÓN</dt><ul>
            <p>Este procedimiento se aplicará a los informantes que trabajen en el sector privado o público y que hayan obtenido información sobre infracciones en un contexto laboral o profesional, comprendiendo en todo caso a los empleados públicos o trabajadores por cuenta ajena; los autónomos; los accionistas, partícipes y personas pertenecientes al órgano de administración, dirección o supervisión de una empresa, incluidos los miembros no ejecutivos; cualquier persona que trabaje para o bajo la supervisión y la dirección de contratistas, subcontratistas y proveedores.</p>
            <p>Esta relación se complementa con lo prescrito en él artículo 3 de la Ley reguladora.</p>
          </ul>

          <dt>PRINCIPALES ASPECTOS ASOCIADOS AL CANAL DE DENUNCIAS</dt><br></br>
          <ul>
            <dl><b> 4.1	Comunicación de la Denuncia</b></dl>
            <ul>
              <p>
                Todo aquel que tuviera conocimiento fundado de cualquier tipo de acto o hecho, acción u omisión, que pudiera constituir infracciones del Derecho de la UE, o que puedan ser constitutivas de infracción penal o administrativa grave o muy grave, o que pudieran suponer un acto ilícito o contrario a la normativa vigente, podrá comunicarlo a la entidad, por escrito a través de esta canal.
              </p>
            </ul>

            <dl><b> 4.2	Hechos denunciables</b></dl><ul>
              <p>
                El Canal de Denuncias debe entenderse como un instrumento que permita la comunicación de irregularidades, incumplimientos o ilícitos. Por ello, NO debe emplearse de manera indiscriminada, sino solo y exclusivamente para los fines que ha sido concebido.
              </p>
              <p>
                Los hechos denunciables deben estar enfocados en conductas, incumplimientos, irregularidades, acciones u omisiones, que pudiera constituir infracciones del Derecho de la UE, o que puedan ser constitutivas de infracción penal o administrativa grave o muy grave, pudiendo constituir un acto ilícito o contrario a la normativa vigente.
              </p>
            </ul></ul>
          <dt>5.	FORMULACION DE LA DENUNCIA Y TRAMITACION</dt><br></br>
          <ul><dl><b>5.1	Tipos de denuncias o comunicaciones</b></dl>
            <ul>
              <p>
                Cualquier persona que tenga relación con la entidad, que conozca la existencia de una conducta irregular, incumplimiento o vulneración de las Normas podrá reportarla inmediatamente mediante la presentación de la correspondiente denuncia en el canal de denuncias, rellenando el formulario habilitado al efecto.
              </p>
              <p>
                La denuncia deberá ser individual. En caso de que varias personas tengan conocimiento del mismo hecho o circunstancia que deba ser comunicado a la entidad, cada una de ellas deberá hacerlo de forma individual a través del canal de denuncias.
              </p>
              <p>
                La misma podrá presentarse de manera anónima o identificada, a elección del denunciante, proporcionado los siguientes datos:
              </p>
              <p><u>Denuncia Anónima:</u></p>
              <ul>
                <li>Seleccionar “Denuncia Anónima”.</li>
                <li>Aceptar “Política de Privacidad”</li>
                <li>Marcar Captcha.</li>
                <li>Seleccionar Centro.</li>
                <li>Formular denuncia: Descripción de los hechos que dan a lugar a la denuncia.</li>
                <li>Seleccionar archivo: adjuntar la documentación probatoria que se desee aportar, en relación con los hechos denunciados.</li>
                <li>Enviar denuncia.</li>
              </ul>

              <p><u>Denuncia identificada</u></p>
              <ul>
                <li>Seleccionar “Denuncia Identificada”.</li>
                <li>Cumplimentar los datos personales requeridos: nombre y apellidos, DNI, email, domicilio y teléfono.</li>
                <li>Aceptar “Política de Privacidad”.</li>
                <li>Marcar Captcha.</li>
                <li>Seleccionar Centro.</li>
                <li>Formular denuncia: Descripción de los hechos que dan a lugar a la denuncia.</li>
                <li>Seleccionar archivo: adjuntar la documentación probatoria que se desee aportar, en relación con los hechos denunciados.</li>
                <li>Enviar denuncia.</li>
              </ul>

              <p>
                Si la comunicación no se cursa a través del presente Sistema Interno de Información y se utilizan otras vías de comunicación, se garantiza la confidencialidad de la información, que será tratada en un entorno seguro atendiendo además a las previsiones de protección de datos personales legalmente exigibles.
              </p>

              <p>
                Las comunicaciones verbales, ya sean mediante reunión presencial o vía telefónica, se documentarán mediante una grabación de la conversación en un formato seguro, duradero y accesible, siempre que se advierta previamente al informante de que su comunicación será grabada, se le informe del tratamiento de sus datos conforme a la Ley aplicable y se recabe su consentimiento. Igualmente, a través de una transcripción completa y exacta de la conversación realizada por el personal responsable de tratarla, en cuyo caso, se le trasladará al informante para comprobar, rectificar y aceptar mediante su firma la transcripción del mensaje.
              </p>

              <p>
                Cualquier persona que conozca la existencia de alguno de los supuestos contemplados en la presente normativa podrá informar a las autoridades competentes nacionales y si fuera el caso a las instituciones de la Unión Europea. En España podrá dirigirse a modo enunciativo a la AII (Autoridad Independiente del Informante), 
              <a href="https://www.aepd.es/">Agencia Española de Protección de Datos</a>, la CNMC (Comisión Nacional de los Mercados y la Competencia) www.cnmc.es, o la CNMV (Comisión Nacional del Mercado de Valores) www.cnmv.es en función de la naturaleza de la irregularidad.
              </p>
              
            </ul>
            <dl><b>5.2 Formulación de la denuncia</b></dl>
            <ul>
              <p>
                La formulación de la denuncia debe ser lo más descriptiva y detallada posible, facilitando de esta forma al receptor la identificación de la conducta potencialmente ilícita y las personas o departamentos implicados.
              </p>
              <p>
                Todo aquel que formule una denuncia deberá hacerlo según un principio irrenunciable de buena fe, con razones suficientes y pruebas objetivas que demuestren la existencia del incumplimiento.
              </p>
              <p>
                En cualquier caso, dada la posibilidad del anonimato en el canal de denuncias, las denuncias contendrán todos los datos que permitan analizar los hechos y en la medida de lo posible la siguiente información:
              </p>

              <ul>
                <li>Exposición clara y detallada de los hechos.</li>
                <li>Identificación de la departamento o Unidad de Negocio en la que hayan tenido lugar</li>
                <li>Identificación de la o las personas involucradas con el comportamiento denunciado o con conocimiento del mismo.</li>
                <li>Momento en el que ocurrió o ha estado ocurriendo el hecho.</li>
                <li>Cuantificación, siempre y cuando sea posible, del impacto del hecho denunciado.</li>
                <li>Aportar, si se considera necesario, documentos, archivos u otra información que se estime relevante para la evaluación y resolución de la denuncia.</li>
              </ul>

              <p>
              Al efectuar la denuncia identificada, el informante podrá indicar un domicilio, correo electrónico o lugar seguro a efectos de recibir las notificaciones.
              </p>

              <p>
              Tanto si la denuncia es anónima como identificada, se le facilitará al denunciante un Código o ID alfanumérico que deberá de conservar para acceder en cualquier momento al estado de su denuncia y documentación adjunta.
              </p>

              <p>
              Salvo que ello pueda poner en peligro la confidencialidad de la comunicación y en el caso de que el denunciante no sea anónimo, se procederá a remitir acuse de recibo de la denuncia en un plazo máximo de siete días a partir de su recepción. Se podrá mantener la comunicación con el informante y si fuera necesario se le podrá solicitar información adicional que pueda ser de utilidad para la tramitación de la denuncia, siempre en un entorno de seguridad y confidencialidad.
              </p>

              <p>
              En el caso de ser una denuncia anónima, cualquier petición de información, comunicación o acuse se colgará como documento, en la página de seguimiento de la misma, por lo que se le recomienda al denunciante que revise dicha página con cierta habitualidad una vez presentada la misma.
              </p>
            </ul>
            <dl><b>5.3	Actos iniciales y clasificación de la denuncia</b></dl>            
            <dl><b>5.3.1 Clasificación de la denuncia y remisión de la misma. </b></dl>
            <ul>
              <p>
              <ul>
              <p>
                A la recepción de las denuncias, se determinará en una primera fase si la información aportada constituye una irregularidad o ilícito que debe ser investigado y los recursos, métodos y procedimientos adecuados para la investigación de cada denuncia teniendo en consideración la naturaleza y gravedad de la misma:
              </p>
              <ul>
                <li>Denuncias de ámbito laboral o de recursos humanos</li>
                <li>Denuncias de ámbito financiero o contable</li>
                <li>Denuncias referentes a otros ámbitos de regulación externa</li>                
              </ul>

              <p>
              Si un hecho denunciado afecta a una Unidad de Negocio o departamento concreto de la entidad (ya sea a una de sus sociedades o a sus empleados), se dará traslado de la denuncia al responsable de la Unidad correspondiente, y coordinará con ésta el análisis de la misma, salvo que la denuncia afecte directamente a un miembro de dicha Unidad.
              </p>

              <p>
              Para el caso de que los hechos pudieran ser calificados como indiciariamente constitutivos de delito en coordinación con el responsable legal y de cumplimiento de la unidad afectada informará al Ministerio Fiscal o a la Fiscalía Europea si los hechos afectasen a intereses europeos de la Unión.
              </p>

              <p>
                Todas las decisiones adoptadas por la entidad en esta fase deberán quedar convenientemente documentadas.
              </p>
              </ul>
              </p>              
            </ul>

            <dl><b>5.3.2 Procedimiento especial en caso de acoso sexual por razón de sexo y otras conductas contrarias a la libertad sexual y la integridad moral en el ámbito de la entidad. </b></dl>
            <ul>
              <p>
              <ul>
              <p>
                Tras la clasificación de la naturaleza de la denuncia, y en el caso que la misma se pueda enmarcar como comportamiento constitutivo de acoso sexual, acoso por razón de sexo,  incluidos los cometidos en el ámbito digital,  además de cualesquiera otras conductas contrarias a la libertad sexual y la integridad moral, dicho procedimiento se tramitará conforme a las pautas establecidas en el protocolo para la prevención y actuaciones frente al acoso sexual, por razón de sexo y otras conductas contrarias a la libertad sexual y la integridad moral aprobad por la entidad.
              </p>              
              </ul>
              </p>              
            </ul>
            
            <dl><b>5.4	Comunicación al informante.</b></dl>
            <ul>
              <p>
                El plazo para finalizar las actuaciones y dar respuesta al informante, en su caso, no podrá ser superior a tres meses desde la entrada en registro de la información. Cualquiera que sea la decisión, se comunicará al informante, salvo que haya renunciado a ello o que la comunicación sea anónima. En cualquier caso, anónima lo identificada, se dejará testimonio de todas las actuaciones en el portal del presente canal.
              </p>
              <p>
                En el caso de denuncias que no constituyan irregularidades, sino que puedan considerarse otro tipo de quejas o solicitud de información, el responsable del sistema referirá las mismas a la unidad, área o departamento correspondiente para que proceda a su análisis o gestión y en su caso, a notificar la respuesta.
              </p>
            </ul>

            <dl><b>5.5	Información al afectado</b></dl>
            <ul>
              <p>
                En el momento y en la forma que el responsable del sistema de la entidad considere adecuado para garantizar el buen fin de la investigación, y en coordinación en su caso, con la Unidad concreta de Negocio, directivos o terceros que apoyen el proceso de investigación, se informará convenientemente a la persona afectada sobre las acciones y omisiones que se le atribuyen. La persona afectada tendrá también derecho a ser oída en cualquier momento durante la investigación de los hechos informados, y su declaración deberá documentarse formalmente. En ningún caso se revelará la identidad del informante.
              </p>
              <p>
                El receptor de la denuncia y el que en su caso la tramite siempre respetará la presunción de inocencia del afectado, así como su derecho al honor.
              </p>
            </ul>
            <dl><b>5.6	Fase de instrucción</b></dl>
            <ul>
              <p>
                El responsable del sistema, una vez evaluada y admitida la denuncia, procederá a la elaboración de un expediente de apertura sobre la denuncia en investigación, dicho expediente contendrá:
              </p>
              <ul>
                <li>Información descriptiva de la denuncia, indicando fecha de recepción.</li>
                <li>Datos aportados, diferenciando los datos objetivos y subjetivos.</li>
                <li>Valoración del contenido de la denuncia y de la fiabilidad del denunciante.</li>
                <li>Personas afectadas.</li>
                <li>Proposición de medias de urgencia a corto plazo, entre ellas, medidas cautelares que permitan minimizar el daño actual o inminente.</li>
                <li>El objetivo del expediente de apertura es recabar la máxima trazabilidad del proceso de investigación, en caso de que la denuncia pueda ser requerida por cualquier autoridad judicial competente.</li>
              </ul>
              <p>
                El responsable del sistema emprenderá, si lo considera necesario, acciones tendentes a profundizar en los hechos y a recabar información adicional para tomar una correcta decisión, de forma objetiva, en base a las evidencias recabadas. Las acciones de investigación pueden contemplar:
              </p>
              <ul>
                <li>Requerir nuevas evidencias y/o pruebas al denunciante para mejorar el proceso de investigación.</li>
                <li>Concertar entrevistas con las personas implicadas.</li>
                <li>Revisar toda aquella información relativa a la relación profesional de los implicados con la Organización, siempre con respeto a las leyes de protección de datos y privacidad de los implicados.</li>
                <li>Análisis forense por un experto externo independiente.</li>
                <li>Cualquier otra acción que considere necesaria para continuar con el proceso de investigación, respetando la normativa vigente en Protección de Datos.</li>

              </ul>
              <p>
                La investigación consistirá, en todo momento, en un análisis objetivo de la documentación disponible, la obtención de evidencias, recolección de pruebas y el análisis de las mismas.
              </p>

              <p>
                El proceso de investigación durará como máximo 3 meses desde el día en que se recibió la denuncia. Transcurridos los 3 meses, los datos podrán seguir siendo tratados para la investigación de los hechos denunciados y en su caso, por las autoridades competentes o en procesos judiciales, pero no se conservarán en el propio sistema de información de denuncias internas.
              </p>

              <p>
                Si en cualquier momento de la fase de instrucción o investigación el responsable del sistema, apoyado por el equipo legal de la entidad, pudiera tener cualquier sospecha fundada que los hechos o actos denunciados pudiera revestir indicios de delito penal, paralizará inmediatamente la instrucción, danto parte de ello, a la mayor brevedad posible, al Ministerio Fiscal para el inicio del correspondiente procedimiento penal.
              </p>
            </ul>
            <dl><b>5.7	Fase de resolución</b></dl>
            <ul>
              <p>
                Una vez recabadas las pruebas y analizadas las mismas por parte del responsable del sistema, se cierra la fase de investigación y se procede a la toma de decisiones acerca de la comunicación analizada, proponiendo a la dirección de la entidad, las medidas que se deberán adoptar.
              </p>
              <p>
                El responsable del sistema podrá adoptar las siguientes medidas:
              </p>
              <ul>
                <li><strong>Denuncia no procedente:</strong> En el supuesto en el que la investigación de los hechos comunicados se manifieste la ausencia de incumplimiento. El responsable del sistema informará al denunciante y al denunciado de dicha decisión, junto con los motivos que sustentan la decisión.</li>
                <li><strong>Denuncia procedente:</strong> En el supuesto que se pruebe que los hechos denunciados son ciertos, se deberá proceder a detallar las acciones que sustentan dicha conclusión. El régimen sancionador se aplicará de conformidad con el Convenio Colectivo aplicable, la legislación laboral y el resto de obligaciones civiles y mercantiles que haya contraído el miembro de la plantilla de la entidad, personal directivo o del Consejo de Administración, sin perjuicio de las responsabilidades penales que pudieran derivarse de la comisión de delitos. Responsable del sistema deberá informar al denunciante y en particular al denunciado para ofrecerle posibilidad de defensa.</li>
              </ul>
            </ul>
            <dl><b>5.8	Protección de datos de carácter personal</b></dl>
            <ul>
              <p>Los datos personales de las denuncias para las que no proceda su investigación, por no estar fundamentadas, deberán borrarse o anonimizarse transcurridos tres meses desde su recepción. Los datos personales de las denuncias que no sean investigadas podrán mantenerse transcurrido este plazo siempre que la finalidad de la conservación sea dejar evidencia del funcionamiento del sistema. La entidad cuenta con un registro de las informaciones recibidas y de las investigaciones llevadas a cabo de acuerdo con lo establecido en la ley. Los datos personales se conservarán durante el periodo que sea necesario y proporcionado a efectos de cumplir con las previsiones legales. En ningún caso podrán conservarse los datos por un período superior a diez años.</p>
              <p>El tratamiento de los datos personales relativos a las informaciones recibidas y a las investigaciones que se realicen por la entidad tendrán como objeto cumplir con las obligaciones legales y realizar las acciones de investigación que se estimen necesarias En ningún caso serán objeto de tratamiento los datos personales, incluidos los de categorías especiales, que no sean necesarios para el conocimiento e investigación de las denuncias, procediéndose, en su caso, a su inmediata supresión.</p>
              <p>Las comunicaciones recibidas por otras vías diferentes al canal de denuncias, como buzones de cumplimiento, de RRHH o emails, deberán eliminarse inmediatamente después de informar al responsable del Sistema. El acceso a los datos personales contenidos en el Sistema de información quedará limitado, dentro del ámbito de sus competencias y funciones, exclusivamente a:</p>

              <ul>
                <li>El responsable del Sistema y a quien lo gestione directamente.</li>
                <li>El responsable de recursos humanos o el órgano competente debidamente designado, solo cuando pudiera proceder la adopción de medidas disciplinarias contra un trabajador.</li>
                <li>El responsable de los servicios jurídicos de la entidad u organismo, si procediera la adopción de medidas legales en relación con los hechos relatados en la comunicación.</li>
                <li>Los encargados del tratamiento que eventualmente se designen.</li>
                <li>El delegado de protección de datos.</li>

              </ul>

              <p>No obstante, será lícito el tratamiento de los datos por otras personas, o incluso su comunicación a terceros, cuando resulte necesario para la adopción de medidas correctoras en la entidad o la tramitación de los procedimientos sancionadores o penales que, en su caso, procedan.</p>
              <p>Si se acreditara que los datos personales facilitados en la comunicación no son veraces se procederá a su inmediata supresión desde el momento en que se tenga constancia de dicha circunstancia, salvo que dicha falta de veracidad pueda constituir un ilícito penal, en cuyo caso se guardará la información por el tiempo necesario durante el que se tramite el procedimiento judicial.</p>
              <p>Los usuarios del canal de denuncias pueden ejercitar, siempre que resulten de aplicación, los derechos de acceso, rectificación, supresión, oposición, limitación y portabilidad respecto de sus datos personales en él recogidos mediante comunicación escrita a la entidad en su domicilio social, acreditando suficientemente su identidad, e identificándose como usuario del Canal de Denuncias.</p>
              <p>Los trabajadores y colaboradores de La entidad, como sujetos pasivos de posibles denuncias, son informados de la existencia de este sistema de denuncias internas, a través de las respectivas políticas de privacidad para trabajadores y colaboradores.</p>
              <p>La entidad se compromete a la utilización de aquellas medidas de carácter técnico que sean necesarias para garantizar la seguridad, integridad, no alteración y el acceso no autorizado de los datos facilitados.</p>
              <p>La entidad actuará en todo momento al amparo de la legislación vigente y de los valores, principios y procedimientos establecidos en su normativa interna.</p>
            </ul>
          </ul>
          <dt>PROHIBICION DE REPRESALIAS</dt>
          <ul>
            <p>Quedan expresamente prohibidos los actos constitutivos de represalia, incluidas las amenazas de represalia y las tentativas de represalia contra las personas que presenten una comunicación conforme a lo previsto en esta ley (informante o denunciante).</p>
            <p>Se entiende por represalia cualesquiera actos u omisiones que estén prohibidos por la ley, o que, de forma directa o indirecta, supongan un trato desfavorable que sitúe a las personas que las sufren en desventaja particular con respecto a otra en el contexto laboral o profesional, solo por su condición de informantes, o por haber realizado una revelación pública.</p>
            <p>A efectos ilustrativos y a título enunciativo, se consideran represalias las que se adopten en forma de:</p>

            <ul>
              <li> Suspensión del contrato de trabajo, despido o extinción de la relación laboral o estatutaria, incluyendo la no renovación o la terminación anticipada de un contrato de trabajo temporal una vez superado el período de prueba, o terminación anticipada o anulación de contratos de bienes o servicios, imposición de cualquier medida disciplinaria, degradación o denegación de ascensos y cualquier otra modificación sustancial de las condiciones de trabajo y la no conversión de un contrato de trabajo temporal en uno indefinido, en caso de que el trabajador tuviera expectativas legítimas de que se le ofrecería un trabajo indefinido; salvo que estas medidas se llevaran a cabo dentro del ejercicio regular del poder de dirección al amparo de la legislación laboral o reguladora del estatuto del empleado público correspondiente, por circunstancias, hechos o infracciones acreditadas, y ajenas a la presentación de la comunicación.</li>
              <li> Daños, incluidos los de carácter reputacional, o pérdidas económicas, coacciones, intimidaciones, acoso u ostracismo.</li>
              <li> Evaluación o referencias negativas respecto al desempeño laboral o profesional.</li>
              <li> Inclusión en listas negras o difusión de información en un determinado ámbito sectorial, que dificulten o impidan el acceso al empleo o la contratación de obras o servicios.</li>
              <li> Denegación o anulación de una licencia o permiso.</li>
              <li> Denegación de formación.</li>
              <li> Discriminación, o trato desfavorable o injusto.</li>
            </ul>

            <p> La persona que viera lesionados sus derechos por causa de su comunicación o revelación una vez transcurrido el plazo de dos años, podrá solicitar la protección de la autoridad competente que, excepcionalmente y de forma justificada, podrá extender el período de protección, previa audiencia de las personas u órganos que pudieran verse afectados. La denegación de la extensión del período de protección deberá estar motivada.</p>
            <p> Los actos administrativos que tengan por objeto impedir o dificultar la presentación de comunicaciones y revelaciones, así como los que constituyan represalia o causen discriminación tras la presentación de aquellas al amparo de esta ley, serán nulos de pleno derecho y darán lugar, en su caso, a medidas correctoras disciplinarias o de responsabilidad, pudiendo incluir la correspondiente indemnización de daños y perjuicios al perjudicado.</p>
            <p> La Autoridad Independiente de Protección del Informante, A.A.I. podrá, en el marco de los procedimientos sancionadores que instruya, adoptar medidas provisionales en los términos establecidos en el artículo 56 de la Ley 39/2015, de 1 de octubre, del Procedimiento Administrativo Común de las Administraciones Públicas.</p>
          </ul>
          <dt>COMUNICACIÓN DE DENUNCIAS FALSAS O DE MALA FE</dt>
          <ul>
            <p>El canal de denuncias debe emplearse de manera responsable y adecuada. La comunicación de hechos falsos, con una actitud maliciosa y moralmente deshonesta, supone una infracción de la buena fe que debe presidir las relaciones laborales, pudiendo derivar en medidas disciplinarias de conformidad con el Convenio Colectivo Vigente o incluso penales.</p>
            <p>Si, tras el oportuno análisis, se pudiera concluir que los hechos denunciados son manifiestamente falsos y que la denuncia ha sido presentada con actitud maliciosa y mala fe:</p>

            <ul>
              <li> Se archivará la denuncia, documentando los motivos que han llevado a archivar el expediente, finalizando la labor de investigación.</li>
              <li> se trasladará dicha circunstancia a la Dirección de RRHH para que, en coordinación con el responsable del sistema, se propongan medidas disciplinarias de conformidad con el Convenio Colectivo Vigente y;</li>
              <li> se informará, de forma escrita, la propuesta de sanción al Comité de Dirección o, en su caso, al Consejo de Administración, quien decidirá la acción disciplinaria a aplicar al denunciante de mala fe.</li>

            </ul>
          </ul>
        </dl>
      </div>
    </div>
  );
}

export default NormasUso;
