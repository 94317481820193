import React, { useContext, useEffect } from "react";
import "../css/Canal.css";
import { useState } from "react";
import captcha from "../assets/captcha.png";
import { Link, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import AppContext from "../AppContext";
import { Padding } from "@mui/icons-material";

function FormularioDenuncias() {
  let { nameCompany } = useParams();
  const [showForm, setShowForm] = useState(false);
  const { data, complainantInfo, setComplainantInfo } = useContext(AppContext);

  const [correoCadiz, setCorreoCadiz] = useState("");

  useEffect(() => {
    if (data.razonSocial === "CADIZ C.F., S.A.D.") {
      setCorreoCadiz("dpd@cadizcf.es");
    } else {
      setCorreoCadiz("");
    }
  }, [data.razonSocial]);
  

  const handleIdentifChange = (event) => {
    // setIsAnonymous(event.target.value);
    console.log(
      "isAnonymous",
      event,
      event.target.value,
      event.target.value !== "identificada"
    );
    setComplainantInfo({
      ...complainantInfo,
      isAnonymous: event.target.value !== "identificada",
    });

    if (event.target.value === "identificada") {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  };

  /* Checkbox */
  const [checkboxValue, setCheckboxValue] = useState(false);

  const handleCheckboxChange = (event) => {
    setCheckboxValue(event.target.checked);
  };

  useEffect(() => {
    if (complainantInfo.isAnonymous)
      setComplainantInfo({
        ...complainantInfo,
        fullName: "",
        nie: "",
        email: "",
        street: "",
        numberStreet: "",
        city: "",
        province: "",
        postalCode: "",
        phoneNumber: "",
      });
  }, [complainantInfo.isAnonymous]);

  const handleChange = (event) => {
    setComplainantInfo({
      ...complainantInfo,
      [event.target.name]: event.target.value,
    });
  };

  function comprobarDni(id) {
    const regexDNI = /^[0-9]{8}[A-Za-z]$/;
    return regexDNI.test(complainantInfo.nie);
  }

  const [dniValido, setDniValido] = useState(true);

  function comprobarCorreo(correo) {
    const regexCorreo = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regexCorreo.test(correo);
  }

  const [correoValido, setCorreoValido] = useState(true);

  const estiloValido = {
    border: "1px solid black",
    maxWidthwidth: "620px",
    marginTop: "10px",
  };

  const estiloInvalido = {
    border: "1px solid red",
    maxWidthwidth: "620px",
    marginTop: "10px",
  };

  const [telfValido, setTelfValido] = useState(true);

  function comprobarTelf(telefono) {
    const regexTelf = /^\d{9}$/;
    return regexTelf.test(telefono);
  }

  const [captchaCorrecto, setCaptchaCorrecto] = useState(false);

  const handleRecaptchaChange = (value) => {
    if (value) {
      setCaptchaCorrecto(true);
      // El reCAPTCHA ha sido verificado correctamente
      // Puedes habilitar el botón de envío aquí
    } else {
      setCaptchaCorrecto(false);
      // El reCAPTCHA no ha sido verificado
      // Puedes deshabilitar el botón de envío aquí
    }
  };

  return (
    <div>
      <h1>{data.razonSocial}</h1>
        <div style={{padding: "0px 7%"}}>
          <p>
            <strong>Protección de datos de carácter personal:</strong> La entidad, tiene la consideración de responsable.
          </p>
          <p>
            <strong>FINALIDAD:</strong> Los datos que facilite serán tratados con la finalidad de gestionar el Sistema Interno de Información en cumplimiento de la Ley 2/2023 de Protección al informante.
          </p>
          <p>
            <strong>LEGITIMACIÓN:</strong> El consentimiento del interesado y el cumplimiento de una obligación legal.
          </p>
          <p>
            <strong>DERECHOS:</strong> Tiene derecho a acceder, rectificar, portar, limitar, oponerse y suprimir los datos. Puede solicitarnos la información adicional y detallada sobre Protección de Datos dirigiéndonos un correo electrónico.  {correoCadiz}
          </p> 
          <p style={{marginBottom: "3rem"}}>
            <strong>DESTINATARIOS:</strong> No se cederán datos a terceros, salvo obligación legal. No se realizarán transferencias internacionales.
          </p>    
        </div>
      <div
        style={{ display: "flex",flexDirection: "column", justifyContent: "space-evenly" }} >
        <div id="contDenuncia" style={{ textAlign: "center" }}>
          <label id="radiob" style={{ marginRight: "20px" }}>
            Denuncia anónima{" "}
            <input
              className="left-radio"
              type="radio"
              name="identificacion"
              value="anonima"
              // checked={isAnonymous === "anonima"}
              checked={complainantInfo.isAnonymous}
              onChange={handleIdentifChange}
            />
          </label>
          <label id="radiob" style={{}}>
            Denuncia con identificación{" "}
            <input
              className="left-radio"
              type="radio"
              name="identificacion"
              value="identificada"
              // checked={isAnonymous === "identificada"}
              checked={!complainantInfo.isAnonymous}
              onChange={handleIdentifChange}
            />
          </label>
        </div>

        {showForm && (
          <form
            style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <div
              id="camposFormDenuncia"
              style={{ marginTop: "25px", width: "800px" }}
            >
              <label>
                <input
                  id="inputFormDenuncia"
                  type="text"
                  value={complainantInfo.fullName}
                  name="fullName"
                  onChange={handleChange}
                  placeholder="Nombre y Apellidos *"
                  style={{
                    width: "100%",
                  }}
                />
              </label>

              <label>
                <input
                  id="inputFormDenuncia"
                  type="text"
                  value={complainantInfo.nie}
                  name="nie"
                  onChange={handleChange}
                  placeholder="Documento de identidad del usuario *"
                  style={{
                    width: "100%",
                    ...(dniValido ? estiloValido : estiloInvalido),
                  }}
                />
              </label>

              <label>
                <input
                  type="text"
                  value={complainantInfo.email}
                  name="email"
                  onChange={handleChange}
                  placeholder="Email *"
                  style={{
                    width: "100%",
                    ...(correoValido ? estiloValido : estiloInvalido),
                  }}
                />
              </label>

              <div
                id="contStreetInput"
                style={{
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <label id="contStreet">
                  <input
                    id="streetInput"
                    type="text"
                    value={complainantInfo.street}
                    name="street"
                    onChange={handleChange}
                    placeholder="Calle"
                  />

                  <input
                    id="numberStreetInput"
                    type="text"
                    value={complainantInfo.numberStreet}
                    name="numberStreet"
                    onChange={handleChange}
                    placeholder="Número"
                  />
                </label>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  width: "100.7%",
                }}
              >
                <label id="contLocation" style={{ width: "98%" }}>
                  <input
                    id="cityInput"
                    type="text"
                    value={complainantInfo.city}
                    name="city"
                    onChange={handleChange}
                    placeholder="Localidad"
                    style={{ width: "98%" }}
                  />
                </label>
                <label style={{ width: "98%" }}>
                  <input
                    id="provinceInput"
                    type="text"
                    value={complainantInfo.province}
                    name="province"
                    onChange={handleChange}
                    placeholder="Provincia"
                    style={{ width: "98%" }}
                  />
                </label>
                <label style={{ width: "98%" }}>
                  <input
                    id="pcInput"
                    type="text"
                    value={complainantInfo.postalCode}
                    name="postalCode"
                    onChange={handleChange}
                    placeholder="CP"
                    style={{ width: "98%" }}
                  />
                </label>
              </div>

              <label>
                <input
                  id="inputFormDenuncia"
                  type="text"
                  value={complainantInfo.phoneNumber}
                  name="phoneNumber"
                  onChange={handleChange}
                  placeholder="Teléfono"
                  style={{
                    width: "100%",
                    ...(telfValido ? estiloValido : estiloInvalido),
                  }}
                />
              </label>
            </div>
          </form>
        )}
      </div>

      <div>
        <p
          id="checkbox"
          style={{
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <input
            type="checkbox"
            id="normasUso"
            name="normasUso"
            onChange={handleCheckboxChange}
          />
          <label
            id="lblCheckbox"
            style={{ marginLeft: "20px" }}
            htmlFor="normasUso"
          >
            He leído y acepto la{" "}
            <a
              // href={`http://${data.urlPrivacidad}`}
              href={
                data.urlPrivacidad.startsWith("http")
                  ? data.urlPrivacidad
                  : `https://${data.urlPrivacidad}`
              }
              target="_blank"
              rel="noreferrer"
            >
              Política de Privacidad
            </a>
          </label>
        </p>
      </div>

      <div style={{ textAlign: "center" }}>
        <ReCAPTCHA
          className="myCAPTCHA"
          sitekey="6LcYSRcmAAAAAJvO-j_TtCLnIL_5H3owPt8uEe3v"
          onChange={handleRecaptchaChange}
        />

        <div
          id="contBotonesFormulario"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Link
            to={`/${nameCompany}`}
            rel="noopener noreferrer"
            style={{
              paddingRight: "2rem",
              // paddingLeft: "2rem",
              fontSize: "16px",
            }}
          >
            <button
              id="botonAtras"
              style={{
                paddingRight: "2rem",
                paddingLeft: "2rem",
                fontSize: "16px",
                marginTop: "60px",
                marginBottom: "100px",
              }}
            >
              Atrás
            </button>
          </Link>

          <Link
            to={`/${nameCompany}/formulario-denuncia/elegir-centro`}
            rel="noopener noreferrer"
          >
            <button
              style={{
                paddingRight: "2rem",
                paddingLeft: "2rem",
                fontSize: "16px",
                marginTop: "60px",
                marginBottom: "100px",
              }}
              disabled={
                !checkboxValue ||
                !captchaCorrecto ||
                (!complainantInfo.isAnonymous &&
                  comprobarDni(complainantInfo.nie) &&
                  comprobarCorreo(complainantInfo.email) &&
                  (complainantInfo.fullName === "" ||
                    complainantInfo.email === "" ||
                    complainantInfo.phoneNumber === ""))
              }
            >
              Siguiente
            </button>
          </Link>
          {/* <button
            style={{ width: "200px" }}
            disabled={
              !checkboxValue ||
              ((complainantInfo.fullName === "" ||
                complainantInfo.email === "" ||
                complainantInfo.phoneNumber === "") &&
                !complainantInfo.isAnonymous) ||
              !captchaCorrecto
            }
            onClick={() => {
              setDniValido(comprobarDni(complainantInfo.nie));
              setCorreoValido(comprobarCorreo(complainantInfo.email));
              setTelfValido(comprobarTelf(complainantInfo.phoneNumber));
            }}
            className={
              checkboxValue ? "" : "botonDesactivado" || !captchaCorrecto
            }
          >
            Siguiente
          </button>

          {(comprobarDni(complainantInfo.nie) &&
            comprobarCorreo(complainantInfo.email) &&
            comprobarTelf(complainantInfo.phoneNumber) &&
            captchaCorrecto) ||
          (complainantInfo.isAnonymous && captchaCorrecto) ? (
            <Link
              to="/formulario-denuncia/elegir-centro"
              rel="noopener noreferrer"
            >
              <button
                style={{
                  width: "200px",
                  marginLeft: "-200px",
                  pointerEvents: checkboxValue ? "" : "none",
                }}
                disabled={!complainantInfo.isAnonymous || !captchaCorrecto}
                className={checkboxValue ? "" : "botonDesactivado"}
              >
                Siguiente
              </button>
            </Link>
          ) : null} */}
        </div>
      </div>
    </div>
  );
}

export default FormularioDenuncias;
