import React from "react";
import "./css/App.css";
//import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//import Formulario from './Formulario/Formulario';
//import FormUserInfo from './Formulario/FormUserInfo';
import Inicio from "./CanalDenuncias/Inicio";
import FormularioDenuncias from "./CanalDenuncias/FormularioDenuncias";
import Alegaciones from "./CanalDenuncias/Alegaciones";
import CodigoEnv from "./CanalDenuncias/CodigoEnv";
import CodigoGen from "./CanalDenuncias/CodigoGen";
import Estado from "./CanalDenuncias/Estado";
import SeleccionCentro from "./CanalDenuncias/SeleccionCentro";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import NormasUso from "./CanalDenuncias/NormasUso";
import { NavBar } from "./NavBar";
import Nav from "./Nav";

window.url_redirect = "https://datagestion.net";
//window.api_base_url = "https://pre.datagestion.net/datagest/api/v1.0/";
//window.api_base_url = "http://localhost:8080/datagest/api/v1.0/";

window.api_base_url = "https://plataforma.datagestion.net/datagest/api/v1.0/";
// const my_swal = withReactContent(Swal);

export function App() {
  let { nameCompany, idCompany } = useParams();
  console.log("App", nameCompany);
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        height: "100vh",
        width: "100vw",
      }}
    >
      <NavBar />
      <BrowserRouter>
        <Routes>
          <Route path="/:nameCompany" element={<Inicio />} />
          <Route
            path="/:nameCompany/formulario-denuncia"
            element={<FormularioDenuncias />}
          />
          <Route
            path="/:nameCompany/formulario-denuncia/elegir-centro"
            element={<SeleccionCentro />}
          />
          <Route
            path="/:nameCompany/formulario-denuncia/elegir-centro/codigo"
            element={<CodigoGen />}
          />

          <Route
            path="/:nameCompany/normas-de-uso-y-funcionamiento"
            element={<NormasUso />}
          />

          <Route
            path="/:nameCompany/consultar-denuncia"
            element={<CodigoEnv />}
          />
          <Route
            path="/:nameCompany/estado-denuncia/:id"
            element={<Estado />}
          />
          <Route
            path="/:nameCompany/estado-denuncia/:id/alegacion"
            element={<Alegaciones />}
          />
        </Routes>
      </BrowserRouter>

      <Nav />
    </div>
  );
}

export default App;
