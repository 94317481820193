import React, { useContext, useEffect } from "react";
import "../css/Canal.css";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppContext from "../AppContext";

function Inicio() {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const { data, setUrlPath } = useContext(AppContext);
  let { nameCompany } = useParams();

  useEffect(() => {
    setUrlPath(nameCompany);
  }, []);

  const handleCheckboxChange = (event) => {
    setCheckboxValue(event.target.checked);
  };

  return (
    <div>
      <h1>{data.razonSocial}</h1>

      <h3>Canal de denuncias online</h3>
      <div id="textoInfo">
        <p>
          En cumplimiento de la{" "}
          <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2023-4513">
            Ley 2/2023, de 20 de febrero, reguladora de la protección de las
            personas que informen sobre infracciones normativas y de lucha
            contra la corrupción.
          </a>
          , establece el presente Sistema interno de información. Esta ley tiene
          por finalidad otorgar una protección adecuada frente a las represalias
          que puedan sufrir las personas físicas que informen sobre alguna de
          las acciones u omisiones a que se refiere su artículo 2.
        </p>
        <p>
          Si su información se encuentra comprendida en estos supuestos, el
          sistema le ira guiando en la formulación de la información/denuncia.
          Si por el contrario, excede este ámbito póngase directamente en contacto con nosotros
        </p>
        <p>
        Usted puede facilitarnos su información de forma completamente anónima o identificándose. En ambos casos podrá seguir su evolución, aportar documentos, o complementar su información durante el tiempo de su tramitación. 
        </p>
        {/* <p>
        En cualquier caso, siempre es libre de acudir al canal externo de la <a href="https://antifraudeandalucia.es/">OAAF</a>, o a las vías administrativa o judicial, en defensa de sus posiciones.
        </p> */}

        <div>
          <p id="checkbox" style={{ marginTop: "50px" }}>
            <input
              type="checkbox"
              id="normasUso"
              name="normasUso"
              onChange={handleCheckboxChange}
            />
            <label
              id="lblCheckbox"
              style={{ marginLeft: "20px" }}
              htmlFor="normasUso"
              //onClick={() => setCheckboxValue(!checkboxValue)}
            >
              He leído y entiendo las{" "}
              <Link
                to={`/${nameCompany}/normas-de-uso-y-funcionamiento`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Normas de Uso y Funcionamiento del Canal de Denuncias
              </Link>
            </label>
          </p>
        </div>

        <div
          id="contBotonesInicio"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          <Link
            to={`/${nameCompany}/formulario-denuncia`}
            rel="noopener noreferrer"
            style={{
              fontSize: "16px",
              pointerEvents: checkboxValue ? "" : "none",
            }}
          >
            <button
              style={{
                fontSize: "16px",
              }}
              disabled={!checkboxValue}
              className={checkboxValue ? "" : "botonDesactivado"}
            >
              {" "}
              Nueva denuncia{" "}
            </button>
          </Link>

          <Link
            to={`/${nameCompany}/consultar-denuncia`}
            rel="noopener noreferrer"
            style={{
              pointerEvents: checkboxValue ? "" : "none",
            }}
          >
            <button
              style={{
                fontSize: "16px",
              }}
              disabled={!checkboxValue}
              className={checkboxValue ? "" : "botonDesactivado"}
            >
              Consultar denuncia
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Inicio;
