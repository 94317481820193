import React from "react";
import logo from "./assets/letras.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useContext } from "react";
import AppContext from "./AppContext";
import { Grid } from "@mui/material";

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <div
        style={{
          position: "absolute",
          bottom: "1rem",
          width: "calc(100% - 2rem)",
        }}
      >
        <div
          style={{
            bottom: "0",
            display: "flex",
            justifyContent: "flex-end",
            position: "relative",
          }}
        >
          <small
            style={{
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            className="smallForm"
          >
            Copyright Datagestión © 2015 - 2023
          </small>

          <NavbarBrand className="navControl" href="/">
            <img src={logo} alt="Logo" /> <span className="orangeColor">|</span>{" "}
            Canal de denuncias
          </NavbarBrand>
        </div>
      </div>
    );
  }
}
