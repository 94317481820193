import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import Nav from "./Nav";
import { NavBar } from "./NavBar";

const AppContext = createContext({});

export function AppContextProvider() {
  const [data, setData] = useState({
    razonSocial: "",
    idCompany: "",
    urlPrivacidad: "www.google.com",
    email: "",
  });
  const [idComplaint, setIdComplaint] = useState("");

  const [complainantInfo, setComplainantInfo] = useState({
    fullName: "",
    nie: "",
    email: "",
    street: "",
    numberStreet: "",
    city: "",
    province: "",
    postalCode: "",
    phoneNumber: "",
    isAnonymous: true,
    report: "",
  });

  const [getComplainantInfo, setGetComplainantInfo] = useState({
    fullName: "Jesus Leon",
    codigoDenuncia: "qEaKLbNb",
    nie: "74937758D",
    email: "jleoncanca@gmail.com",
    street: "",
    numberStreet: "",
    city: "",
    province: "",
    postalCode: "",
    phoneNumber: "652523528",
    isAnonymous: false,
    report:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    url: false,
    historyDenuncia: [
      // {
      //   report: "reportText1",
      //   url: "urlText1",
      //   subject: "subjectText1",
      //   dateCreation: 1686440013000,
      // },
      // {
      //   report: "reportText2",
      //   // url: "urlText2",
      //   subject: "subjectText2",
      //   dateCreation: 1686490013000,
      // },
      // {
      //   report: "reportText3",
      //   url: "urlText3",
      //   subject: "subjectText3",
      //   dateCreation: 1686040013000,
      // },
    ],
  });

  const [urlImageState, setUrlImageState] = useState("");

  const [urlPath, setUrlPath] = useState();

  useEffect(() => {
    console.log("APPCONTEXT urlPath", urlPath);
    if (urlPath === undefined) return;
    var url = window.api_base_url + "denuncia/checkURL/" + urlPath;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        console.log("response", response);
        const jsonData = await response.json();
        console.log("jsonData", jsonData);
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        window.location.href = window.url_redirect;
      }
    };

    fetchData();
  }, [urlPath]);

  return (
    <AppContext.Provider
      value={{
        data,
        setData,
        idComplaint,
        setIdComplaint,
        complainantInfo,
        setComplainantInfo,
        urlImageState,
        setUrlImageState,
        urlPath,
        setUrlPath,
        getComplainantInfo,
        setGetComplainantInfo,
      }}
    >
      <App />
    </AppContext.Provider>
  );
}

export default AppContext;
