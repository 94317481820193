import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { createRoot } from "react-dom/client";
import "./css/index.css";
import Nav from "./Nav";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./AppContext";
import "bootstrap/dist/css/bootstrap.min.css";

//import * as serviceWorker from './serviceWorker';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<AppContextProvider />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
